import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Row, Col, Image } from 'react-bootstrap';
import PageTemplate from '../PageTemplate/PageTemplate';
import { useCart } from '../../context/CartContext';
import { Link } from 'react-router-dom';
import './Chart.css';
import { useTranslation } from 'react-i18next';

const Chart = () => {
    const { t } = useTranslation();
    const { cartItems, setCartItems } = useCart();
    const [deletingIndex, setDeletingIndex] = useState(null);

    useEffect(() => {
        const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(savedCart);
    }, [setCartItems]);

    // Удаление товара с анимацией
    const handleRemoveItem = (index) => {
        setDeletingIndex(index); // Устанавливаем индекс для анимации
        setTimeout(() => {
            const updatedCart = cartItems.filter((_, i) => i !== index);
            setCartItems(updatedCart);
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            setDeletingIndex(null);
        }, 300); // Время анимации должно совпадать с CSS
    };

    const handleQuantityChange = (change, index) => {
        const updatedCart = cartItems.map((item, i) => {
            if (i === index) {
                const newQuantity = Math.max(1, item.quantity + change);
                return {
                    ...item,
                    quantity: newQuantity,
                    totalPrice: item.originalPrice * newQuantity
                };
            }
            return item;
        });
        setCartItems(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const totalAmount = cartItems.reduce((total, item) => total + (item.totalPrice || 0), 0);

    return (
        <div className="chart-page">
            <PageTemplate>
                <div className="chart-wrapper">
                    <h1 className="mb-3">{t('cart')}</h1>
                    <div className="page-underline"></div>
                    <p className="cart-summary">
                        {t('cart_summary.there')} {cartItems.length === 1 ? t('cart_summary.is') : t('cart_summary.are')} <strong>{cartItems.length}</strong> {t('cart_summary.product')}{cartItems.length === 1 ? "" : t('cart_summary.s')} {t('cart_summary.in_the_cart')} <strong>{t('cart_summary.for_the_amount')} {totalAmount}</strong>
                    </p>
                    <div className="cart-links">
                        <a href="/#catalog">{t('continue')}</a>
                        <a href="#" onClick={() => { setCartItems([]); localStorage.removeItem('cart'); }}>{t('clear')}</a>
                    </div>

                    {cartItems.length === 0 ? (
                        <div className="page-subtitle">{t('empty')}</div>
                    ) : (
                        <ListGroup className="cart-items">
                            {cartItems.map((item, index) => (
                                <ListGroup.Item
                                    key={index}
                                    className={`cart-item mb-3 ${deletingIndex === index ? 'slide-out-left' : ''}`}
                                >
                                    <div className="card-header-submit">
                                        <div className="submit-title submit-name">{t('name')}</div>
                                        <div className="submit-title submit-qty">{t('qty')}</div>
                                        <div className="submit-title submit-cost">{t('cost')}</div>
                                    </div>
                                    <Row className="align-items-center">
                                        <Col xs={1} className="card-btn">
                                            <button variant="link" className="card-close" onClick={() => handleRemoveItem(index)}>
                                                <img src={`${process.env.PUBLIC_URL}/images/icons/union.svg`} alt="" />
                                            </button>
                                        </Col>
                                        <Col xs={2} className='card-image'>
                                            <Image src={item.imageUrl || `${process.env.PUBLIC_URL}/${item.image}`} rounded fluid />
                                        </Col>
                                        <Col xs={3} className='card-title'>
                                            <h5>{item.title}</h5>
                                            {item.selectedOption && (
                                                <p className="text-muted">{t('selected_option')}: {item.selectedOption.variant} {t('pills')}</p>
                                            )}
                                        </Col>
                                        <Col xs={2} className="text-center card-quantity">
                                            <div className="quantity-selector">
                                                <div className="quantity-selector-nmbr">{item.quantity}</div>
                                                <div className="quantity-selector-buttons">
                                                    <button onClick={() => handleQuantityChange(1, index)}>▴</button>
                                                    <button onClick={() => handleQuantityChange(-1, index)}>▾</button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={2} className="card-price">
                                            <h5>€{item.totalPrice}</h5>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}

                    <div className="btn-confirm-wrapper">
                        <Link as={Link} to="/order">
                            <Button className="btn-confirm" size="lg">{t('confirm')}</Button>
                        </Link>
                    </div>
                </div>
            </PageTemplate>
        </div>
    );
};

export default Chart;
