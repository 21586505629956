import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

// Импортируйте ваши переводы
const resources = {
    de: {
        translation: {
            search: "Suchen",
            discreet_packaging: "diskrete Verpackung",
            anonymous_delivery: "anonyme Zustellung",
            delivery_guarantee: "Zustellgarantie",
            effective_solution: "<h1><div data-aos='fade-right' data-aos-delay='300'>Eine effektive </div><br /><div data-aos='fade-right' data-aos-delay='600'><em>Lösung</em> zur Verbesserung </div><br /><div data-aos='fade-right' data-aos-delay='900'>der männlichen Potenz!</div></h1>",
            watch_catalog: "Katalog ansehen",
            lorem: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id semper leo, eu malesuada ante.",
            ipsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id semper leo, eu malesuada ante.",
            select_options: "Optionen auswählen",

            catalog: "Katalog",
            payment: "Zahlung",
            delivery: "Lieferung",
            article: "Artikel",
            faq: "FAQ",
            contacts: "Kontakte",
            cart: "Warenkorb",
            order: "Befehl",
            thanks: "Danke",

            all_products: "Alle Produkte",
            all_rights: "Vitapillen.org <br /> Alle Rechte vorbehalten.",
            privacy_policy: "Datenschutzrichtlinie",
            terms_of: "Nutzungsbedingungen",
            continue: "Weiter zum Einkauf",

            search_result: "Suchergebnis",
            no_cure: "Es konnte keine Heilung gefunden werden.",

            home: "Heim",

            // catalog
            promotion: "Förderung",
            new: "Neu",
            recommended: "Wir empfehlen",
            hit: "Schlag",

            // payment
            you_can: "Sie können Medikamente bezahlen, indem Sie Geld auf ein Bankkonto oder eine Kreditkarte überweisen.",
            for_faster: "Für schnellere Überweisungen verwenden Sie bitte Online-Banking. Wenn Sie online bezahlen, wird das Geld innerhalb von 24 Stunden überwiesen.",
            once_the: "Sobald das Geld auf unserem Konto erscheint, senden wir Ihnen das ausgewählte Produkt innerhalb von 6 Stunden zu.",
            to_order: "Um die Medikamente auf unserer Website zu bestellen, legen Sie bitte die gewünschten Artikel in den Warenkorb und gehen Sie zur Kasse. Füllen Sie dann alle Felder aus und klicken Sie auf „Bestellen“. Sie erhalten eine Bestätigung Ihrer Bestellung mit Einzelheiten zur Zahlung per E-Mail.",
            we_accept: "Wir akzeptieren Zahlungen per:",
            sepa_credit: "SEPA-Überweisung auf das Bankkonto",
            transferring_funds: "Überweisung direkt auf unsere Kreditkarte.",
            please_specify: "Bitte geben Sie Ihre Bestellnummer in den Hinweisen zur Zahlung an, z. B. „NR. 75“ oder nur „75“.",

            // delivery
            our_product: "Unser Produkt wird in einem Umschlag geliefert und die Lieferzeit innerhalb der EU-Länder beträgt etwa 4-5 Werktage. Sobald das Geld auf unserem Konto eingegangen ist, senden wir Ihnen das ausgewählte Produkt innerhalb von 6 Stunden zu.",
            the_drugs: "Die Medikamente werden aus EU-Ländern in einem Umschlag verschickt, sodass es keine Probleme mit dem Zoll gibt. Wenn Sie nicht zu Hause sind, erhalten Sie eine Benachrichtigung in Ihrem Briefkasten. Das Paket ist 1 Woche lang auf dem Postweg.",
            the_orders: "Bestellungen von mehr als 40 Pillen können in mehreren Paketen verschickt werden, die möglicherweise nicht am selben Tag ankommen. Unser Shop übernimmt keine Verantwortung für Lieferverzögerungen, langwierige Bearbeitung oder fehlende Benachrichtigung über die erfolglose Lieferung.",

            // article
            here_is_article: "Hier ist der Artikel",

            // faq
            what_does: "Was bedeutet der Begriff „Generika“?",
            the_term: "Der Begriff „generisch“ im Zusammenhang mit Generika bedeutet eine „Kopie“, die mit Markenmedikamenten bioidentisch ist. Generika haben die gleichen aktiven chemischen Verbindungen und die gleiche Absorptionsrate wie Markenmedikamente.",
            the_generic: "Bei den von uns gelieferten Generika handelt es sich um hochwertige Produkte mit der gleichen Dosierung, Stärke und Qualität wie Markenmedikamente.",

            why_are: "Warum sind Generika günstiger als Markenmedikamente?",
            manufacturers_of: "Hersteller von Generika haben im Gegensatz zu Markenherstellern keine Kosten für Forschung, Entwicklung, Marketing oder Werbung. Diese Einsparungen geben wir an Sie, den Kunden, weiter.",
            all_of_the: "Alle von uns gelieferten Generika werden legal in Indien hergestellt.",

            can_the_wording: "Kann der Wortlaut auf den Etiketten von Generika von dem von Markenmedikamenten abweichen?",
            yes_the_wording: "Ja. Die Formulierungen auf den Etiketten von Generika und Markenmedikamenten können unterschiedlich sein. Eventuelle Unterschiede in den Formulierungen von Generika dienen lediglich der Einhaltung von Vorschriften und beeinträchtigen die Wirksamkeit des Medikaments in keiner Weise.",

            can_the_shape: "Kann die Form von Generikapillen von der von Markenmedikamenten abweichen?",
            yes_the_shape: "Ja. Die Form und Farbe von Generika-Medikamenten kann sich geringfügig von der von Markenmedikamenten unterscheiden. Unterschiede in Form und Farbe beeinträchtigen jedoch in keiner Weise die Wirksamkeit des Medikaments.",

            // contacts
            please_contact_us: "Nehmen Sie Kontakt mit uns auf.",

            // cart 
            cart_summary: {
                there: "Es",
                is: "gibt",
                are: "gibt",
                product: "produkt",
                s: "",
                in_the_cart: "im warenkorb",
                for_the_amount: "für den Betrag von EUR",
            },
            clear: "Den Einkaufswagen leeren",
            empty: "Ihr Warenkorb ist leer.",
            name: "Name",
            qty: "Menge",
            cost: "Kosten",
            selected_option: "Ausgewählte Option",
            pills: "Pillen",
            confirm: "Bestätigen",

            // product page description
            please_select: "Bitte wählen Sie eine Produktvariante.",
            choose: "Wählen Sie eine Menge",
            added_to_cart: "Hinzugefügt",
            add_to_cart: "Hinzufügen",
            description: "Beschreibung",
            product_description: "Produktbeschreibung",
            active_ingredient: "Wirkstoff",
            country_of_origin: "Ursprungsland",
            general_use: "Allgemeine Verwendung",
            dosage_and_application: "Dosierung und Anwendungsgebiet",
            precautions: "Vorsichtsmaßnahmen",
            сontraindications: "Kontraindikationen",
            possible_side_effects: "Mögliche Nebenwirkungen",
            interactions: "Wechselwirkungen",
            missed_dose: "Verpasste Dosis",
            overdose: "Überdosierung",
            storage: "Aufbewahrung",
            responsibility: "Verantwortung",
            packaging: "Verpackung",

            // order
            edit: "Bearbeiten",
            delivery_address: "Lieferadresse",
            required_fields: "Pflichtfelder",
            please_fill_in_the: "Bitte füllen Sie die erforderlichen Felder aus: Stadt, Adresse und Postleitzahl.",
            please_fill_in_all: "Bitte füllen Sie alle erforderlichen Felder aus: Name, Nachname, Telefonnummer, E-Mail, Stadt, Adresse und Postleitzahl.",
            placing_an_order: "Eine Bestellung aufgeben",
            country: "Land",
            city: "Stadt",
            address: "Adresse",
            postal_code: "Postleitzahl",
            save_address: "Adresse speichern",
            cart_items: "Warenkorbartikel",
            recipient: "Empfänger",
            name: "Vorname",
            surname: "Nachname",
            phone: "Telefon",
            email: "E-Mail",
            total: "Gesamt",
            for_the_sum: "für die Summe",
            delivery_cost: "Versandkosten",
            to_be_paid: "Zu bezahlen",
            confirm_order: "Bestellung bestätigen",

            // thank you
            thank_you: "Vielen Dank für Ihre Bestellung!",
            please_wait: "Bitte warten Sie, unser Operator wird Sie zur Klärung der Daten kontaktieren."
            // Добавьте остальные переводы на немецкий
        }
    },
    en: {
        translation: {
            search: "Search",
            discreet_packaging: "discreet packaging",
            anonymous_delivery: "anonymous delivery",
            delivery_guarantee: "delivery guarantee",
            effective_solution: "<div data-aos='fade-right' data-aos-delay='300'>An effective </div><br /><div data-aos='fade-right' data-aos-delay='600'><em>solution</em> to improve </div><br /><div data-aos='fade-right' data-aos-delay='900'>male potency!</div>",
            watch_catalog: "Watch Catalog",
            lorem: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id semper leo, eu malesuada ante.",
            ipsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id semper leo, eu malesuada ante.",
            select_options: "Select Options",

            catalog: "Catalog",
            payment: "Payment",
            delivery: "Delivery",
            article: "Article",
            faq: "FAQ",
            contacts: "Contacts",
            cart: "Cart",
            order: "order",
            thanks: "thank you",

            all_products: "All products",
            all_rights: "Vitapillen.org <br /> All rights reserved.",
            privacy_policy: "Privacy Policy",
            terms_of: "Terms of Use",
            continue: "Continue to shopping",

            search_result: "Search result",
            no_cure: "No cure could be found.",

            home: "Home",

            // catalog
            promotion: "Promotion",
            new: "New",
            recommended: "Recommended",
            hit: "Hit",

            // payment
            you_can: "You can pay for Drugs by transferring funds to a Bank account or a credit card.",
            for_faster: "For faster transfers, please use Online Banking. If you pay online, funds will be transferred within 24 hours.",
            once_the: "Once the money appears in our account, we will send you the selected product within 6 hours.",
            to_order: "To order the Drugs on our Website, please put the desired items in the shopping cart and go to checkout. Then fill in all the fields and click on “Order.” You will get a confirmation of your order with details for payment by E-Mail.",
            we_accept: "We accept payment via:",
            sepa_credit: "SEPA Credit Transfer on Bank Account",
            transferring_funds: "Transferring funds directly into our credit card.",
            please_specify: "Please specify your Order Number in the notes to the payment, for example, “NO. 75” or just “75.”",

            // delivery
            our_product: "Our product delivers in envelope and it takes approximately 4-5 working days within the EU countries. Once the money in our account appears, we will send you the selected product in the course of 6 hours.",
            the_drugs: "The drugs will be shipped from EU-countries in envelope, so there won't any be any problems with customs. If you are not at home, you get a notification deposited in your mailbox. The package will be in the post for 1 week.",
            the_orders: "The orders more than 40 pills could be sent in multiple packets which may not arrive on the same day. Our shop takes no responsibility for delay of delivery, the lengthy processing, the lack of notification about the unsuccessful delivery.",

            // article
            here_is_article: "Here is Article",

            // faq
            what_does: "What Does the Term 'Generic' mean?",
            the_term: "The term 'generic' in the context of generic medications, means a 'copy' that is bio-identical to brand name medications. Generic medications have the same active chemical compounds and the same rate of absorption as brand name medications.",
            the_generic: "The generic medications we supply are high quality products that have the same dosage, strength and quality as brand name medications.",

            why_are: "Why are Generic Medications Cheaper than Brand Name Medications?",
            manufacturers_of: "Manufacturers of generic medications do not have the costs of research, development, marketing, or advertising that brand name manufacturers do. Those savings are passed on to you, the customer.",
            all_of_the: "All of the generic medications we supply are legally manufactured in India.",

            can_the_wording: "Can the Wording on Generic Medication Labels Differ from Brand Name Medications?",
            yes_the_wording: "Yes. The wording on labels can differ between generic and brand name medications. Any difference in the wording of generic medications is simply to comply with regulations and does not compromise the effectiveness of the medication in any way.",

            can_the_shape: "Can the Shape of Generic Medications Pills Differ from Brand Name Medications?",
            yes_the_shape: "Yes. The shape of generic medication pills can differ slightly in colour or shape from brand name pills. However, any difference in shape or colour does not compromise the effectiveness of the medication in any way.",

            // contacts
            please_contact_us: "Please contact us.",

            // cart 
            cart_summary: {
                there: "There",
                is: "is",
                are: "are",
                product: "product",
                s: "s",
                in_the_cart: "in the cart",
                for_the_amount: "for the amount of EUR",
            },
            clear: "Clear the cart",
            empty: "Your cart is empty.",
            name: "Name",
            qty: "Qty",
            cost: "Cost",
            selected_option: "Selected option",
            pills: "pills",
            confirm: "Confirm",

            // product page description
            please_select: "Please select a product variant.",
            choose: "Choose a quantity",
            added_to_cart: "Added to Cart",
            add_to_cart: "Add to Cart",
            description: "Description",
            product_description: "Product Description",
            active_ingredient: "Active ingredient",
            country_of_origin: "Country of origin",
            general_use: "General use",
            dosage_and_application: "Dosage and application area",
            precautions: "Precautions",
            сontraindications: "Contraindications",
            possible_side_effects: "Possible side effects",
            interactions: "Interactions",
            missed_dose: "Missed dose",
            overdose: "Overdose",
            storage: "Storage",
            responsibility: "Responsibility",
            packaging: "Packaging",

            // order
            edit: "Edit",
            delivery_address: "Delivery Address",
            required_fields: "Required Fields",
            please_fill_in_the: "Please fill in the required fields: City, Address and Postal Code.",
            please_fill_in_all: "Please fill in all required fields: Name, Surname, Phone, Email, City, Address, and Postal Code.",
            placing_an_order: "Placing an order",
            country: "Country",
            city: "City",
            address: "Address",
            postal_code: "Postal Code",
            save_address: "Save Address",
            cart_items: "Cart Items",
            recipient: "Recipient",
            name: "Name",
            surname: "Surname",
            phone: "Phone",
            email: "E-mail",
            total: "Total",
            for_the_sum: "for the sum",
            delivery_cost: "Delivery Cost",
            to_be_paid: "To be paid",
            confirm_order: "Confirm Order",

            // thank you
            thank_you: "Thank you for your order!",
            please_wait: "Please wait, our operator will contact you to clarify the data."
            // Добавьте остальные переводы на английский
        }
    }
};

// Определите язык по умолчанию
const defaultLanguage = Cookies.get('i18next') || 'de';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
