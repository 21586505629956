import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import productsData from '../../products.json'; // Импортируйте ваш JSON файл
import { Row, Col, Card } from 'react-bootstrap';
import PageTemplate from '../PageTemplate/PageTemplate';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchResults = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (query) {
      const filteredResults = productsData.filter(product =>
        product.title.toLowerCase().includes(query.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      // Если строка поиска пуста, показываем все товары
      setResults(productsData);
    }
  }, [query]);

  return (
    <PageTemplate>
      <h1 className='search-page-adapt'>{t('search_result')} : {query || t('all_products')}</h1>
      <div className="page-underline"></div>
      {results.length > 0 ? (
        <Row xs={1} sm={2} md={3} className="g-6">
          {results.map(product => (
            <Col key={product.id}>
              <Card>
                <div className="img-container">
                  <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/${product.imageUrl}`} alt={product.title} />
                </div>
                <Card.Body>
                  <div>
                    <Card.Title>{product.title}</Card.Title>
                    <Card.Text>
                      €{product.price.from} - €{product.price.to}
                    </Card.Text>
                  </div>
                  <Link to={`/${product.title}`} className='btn'>{t('select_options')}</Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="page-subtitle">
          <p>{t('no_cure')}</p>
        </div>
      )}
    </PageTemplate>
  );
};

export default SearchResults;
