import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import productsData from '../../products.json';
import './Catalog.css';
import { Element } from 'react-scroll';
import PageTemplate from '../PageTemplate/PageTemplate';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

async function fetchConfig() {
  const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
  if (!response.ok) {
    throw new Error("Failed to load configuration");
  }
  return response.json();
}

function Catalog() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3); // Количество отображаемых товаров для мобильной версии
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Проверка на мобильную версию

  useEffect(() => {
    setProducts(productsData);

    fetchConfig().then((config) => setDiscounts(config.discounts)).catch(console.error);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Функция для получения скидки для товара
  const getDiscountInfo = (productTitle) => {
    return discounts.find((item) => item.title === productTitle) || {};
  };

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 3); // Увеличение количества отображаемых товаров на 3
  };

  // Функция для отслеживания кликов на товары
  const handleProductClick = (productTitle) => {
    // Проверка, что объект RedTrackEvent доступен
    if (window.RedTrackEvent) {
      window.RedTrackEvent.track('product_click', { product_name: productTitle });
    } else {
      console.warn('RedTrackEvent is not initialized');
    }
  };

  return (
    <Element name="section1">
      <PageTemplate>
        <Row xs={1} sm={2} md={3} className="g-6">
          {(isMobile ? products.slice(0, visibleCount) : products).map((product) => {
            const { discountPercentage = 0, promotion, new: isNew, recommended, hit } = getDiscountInfo(product.title);
            const discountedPrice = discountPercentage
              ? (product.price.from * (1 - discountPercentage / 100)).toFixed(2)
              : product.price.from;
            return (
              <Col key={product.id}>
                <Card>
                  <div className="img-container">
                    <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/${product.imageUrl}`} alt={product.title} />
                    <div className="sticker-container">
                      {discountPercentage > 0 ? (
                        <div className="sticker discount-percentage">-{discountPercentage}%</div>
                      ) : null}

                      {promotion && <div className="sticker sticker-string promotion">{t('promotion')}</div>}
                      {isNew && <div className="sticker sticker-string new">{t('new')}</div>}
                      {recommended && <div className="sticker sticker-string recommended">{t('recommended')}</div>}
                      {hit && <div className="sticker sticker-string hit">{t('hit')}</div>}
                    </div>
                  </div>
                  <Card.Body>
                    <div>
                      <Card.Title>{product.title}</Card.Title>
                      <Card.Text>
                        €{product.price.from} - €{product.price.to}
                      </Card.Text>
                    </div>
                    <Link
                      to={`/${product.title}`}
                      className='btn'
                      onClick={() => handleProductClick(product.title)}
                    >
                      {t('select_options')}
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>

        {isMobile && visibleCount < products.length && (
          <div className="d-flex justify-content-center mt-3 mx-auto">
            <Button onClick={handleShowMore}>
              Load More
              <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} alt="" />
            </Button>
          </div>
        )}
      </PageTemplate>
    </Element>
  );

}

export default Catalog;
