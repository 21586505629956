import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-scroll';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Catalog from '../Catalog/Catalog';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';

function Home() {
    const location = useLocation();
    const { t } = useTranslation();
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isReversing, setIsReversing] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            setIsReversing(true); // Устанавливаем состояние реверса перед сменой слайда
            setTimeout(() => setIsReversing(false), 1000); // Задержка для синхронизации с анимацией
        },
        afterChange: (current) => {
            setActiveSlide(current); // Обновляем активный слайд
        },
    };

    useEffect(() => {
        if (location.hash === '#catalog') {
            const element = document.getElementById('catalog');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };
    }, [location]);

    return (
        <div className='main'>
            <section className='main-section'>
                <Slider ref={sliderRef} {...settings}>
                    <div className={`slide slide-1 ${isReversing ? 'reverse' : 'animate'}`}>
                        <Container>
                            <div className="badge-wrapper badge-1">
                                <div className="badge-title-block">
                                    <span>{t('discreet_packaging')}</span>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/icons/dot.svg`} alt="" className="badge-dot" />
                            </div>
                            <div className="badge-wrapper badge-2">
                                <div className="badge-title-block">
                                    <span>{t('anonymous_delivery')}</span>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/icons/dot.svg`} alt="" className="badge-dot" />
                            </div>
                            <div className="badge-wrapper badge-3">
                                <div className="badge-title-block">
                                    <span>{t('delivery_guarantee')}</span>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/icons/dot.svg`} alt="" className="badge-dot" />
                            </div>
                        </Container>
                    </div>
                    <div className={`slide slide-2 ${isReversing ? 'reverse' : 'animate'}`}>
                        <Container>
                            <div className="badge-wrapper badge-1">
                                <div className="badge-title-block">
                                    <span>{t('discreet_packaging')}</span>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/icons/dot.svg`} alt="" className="badge-dot" />
                            </div>
                            <div className="badge-wrapper badge-2">
                                <div className="badge-title-block">
                                    <span>{t('anonymous_delivery')}</span>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/icons/dot.svg`} alt="" className="badge-dot" />
                            </div>
                            <div className="badge-wrapper badge-3">
                                <div className="badge-title-block">
                                    <span>{t('delivery_guarantee')}</span>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/icons/dot.svg`} alt="" className="badge-dot" />
                            </div>
                        </Container>
                    </div>
                </Slider>
                <div className="slider-controls">
                    <div
                        className={`control-bar ${activeSlide === 0 ? 'active' : ''}`}
                        onClick={() => sliderRef.current.slickPrev()}
                    ></div>
                    <div
                        className={`control-bar ${activeSlide === 1 ? 'active' : ''}`}
                        onClick={() => sliderRef.current.slickNext()}
                    ></div>
                </div>
                <Container>
                    <div className="content-wrapper">
                        <div className="content-title">
                            <h1 dangerouslySetInnerHTML={{ __html: t('effective_solution') }} />
                        </div>
                        <div className="content-buttons">
                            <Link
                                to="section-header"
                                smooth={false}
                                duration={500}
                                className="main-content-btn"
                                data-aos="fade-up"
                                data-aos-delay="200">
                                <div className='btn-text'>{t('watch_catalog')}</div>
                                <div className='btn-icon'></div>
                            </Link>
                            <div className="contact-buttons">
                                <a href="#" data-aos="fade-up" data-aos-delay="400">
                                    <button className="btn-instagram"></button>
                                </a>
                                <a href="#" data-aos="fade-up" data-aos-delay="600">
                                    <button className="btn-facebook"></button>
                                </a>
                                <a href="#" data-aos="fade-up" data-aos-delay="800">
                                    <button className="btn-twitter"></button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Container>
                <div className="section-header" id="catalog">
                    <div className="section-wrapper wrapper-1">
                        <h1 className="object-title" data-aos="fade-right">{t('catalog')}</h1>
                        <div className="object-underline" data-aos="fade-right"></div>
                        <p className="object-subtitle" data-aos="fade-right">{t('lorem')}</p>
                    </div>
                    <div className="section-wrapper wrapper-2">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/quotes.svg`} alt="" data-aos="zoom-in" />
                        <p className="object-subtitle" data-aos="zoom-in">{t('ipsum')}</p>
                    </div>
                </div>
            </Container>
            <Catalog />
        </div>
    )
}

export default Home;